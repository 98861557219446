#ck-app .ck-background-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

#ck-app .bg-gray-500 {
  --tw-bg-opacity: unset !important;
}

#ck-app .ck-modal {
  background-color: #282c34 !important;
}

#ck-app .text-gray-800,
#ck-app .text-gray-500,
#ck-app .ck-step-header-text,
#ck-app .ck-step-description-text,
#ck-app .ck-step .text-brand-black {
  color: rgb(230, 229, 232) !important;
}

#ck-app textarea {
  color: rgb(230, 229, 232);
  background-color: #282c34 !important;
  border-color: rgba(255, 255, 255, 0.23) !important;
  --tw-ring-color: #00abd8;
}

#ck-app .text-client-primary {
  color: #00abd8;
}

#ck-app .title,
#ck-app .subtitle {
  color: rgb(230, 229, 232) !important;
}

#ck-app .ck-step-body {
  min-height: initial !important;
}

#ck-app .pt-10 {
  padding-top: 0 !important;
}

#ck-app .ck-step-footer {
  border-top-width: 0 !important;
}

#ck-app .ck-gray-primary-button,
#ck-app .ck-black-primary-button {
  background-color: #00abd8 !important;
  color: rgba(29, 31, 39) !important;
  font-weight: 500 !important;
  outline: none;
}
